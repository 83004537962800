<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
      sm="12"
      class="pt-0"
    >
      <carousel />
      <news-grid :max="4" />
      <session-title
        class="pt-8 px-10 pb-5 ms-1"
        title="fotos"
      />
      <gallery-cards :max="4" />
    </v-col>
  </v-row>
</template>

<script>
import Carousel from '@/components/Carousel'
import SessionTitle from '@/components/SessionTitle'
import GalleryCards from '@/components/GalleryCards'
import NewsGrid from '../../components/NewsGrid'

export default {
  name: 'Home',
  components: {
    carousel: Carousel,
    'session-title': SessionTitle,
    'news-grid': NewsGrid,
    'gallery-cards': GalleryCards
  }
}
</script>
