<template>
  <v-container fluid>
    <v-row
      v-if="gallery.length > 0"
      align="center"
      justify="start"
      class="px-12"
    >
      <v-col
        v-for="item in gallery"
        :key="item.id"
        cols="12"
        md="3"
        sm="6"
      >
        <v-hover
          v-slot="{ hover }"
          open-delay="20"
        >
          <v-card
            :elevation="hover ? 16 : 0"
            color="indigo lighten-5"
            class="mx-auto"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.9)"
              :src="item.galleryPhoto[0].photo.link"
            >
              <v-card-title style="word-break: break-word">
                {{ item.name }}
              </v-card-title>
            </v-img>
            <v-card-subtitle class="text-uppercase pt-6 px-6 pb-3">
              {{ item.createdAt | dateFormatted }}
            </v-card-subtitle>
            <v-card-actions class="px-6 pb-6">
              <v-btn
                text
                color="secondary"
                :to="`/photo/${item.slug}`"
              >
                Visualizar Fotos
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row
      v-else
      align="center"
      justify="center"
      class="px-12"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        <h3 class="text--disabled">
          Não há nenhuma galeria por enquanto.
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { findAll } from '../services/gallery-service'
export default {
  name: 'GalleryCards',
  props: {
    max: {
      type: Number,
      default: () => 0
    }
  },
  data: () => ({
    gallery: []
  }),
  async created () {
    try {
      const data = await findAll()
      this.gallery = data

      if (this.max > 0) {
        this.gallery = this.gallery.slice(0, this.max)
      }
    } catch (e) {
      this.$handleHttpError(e)
    }
  }
}
</script>
